export default function ProfileFollow({
     classColor
    ,onClick
    ,message
    ,shrink
}) {
    return (<>
                 <div className={`flex-0 sm:flex-1 flex items-center content-center`}>
                        <div className="ml-2 sm:ml-5">
                          <div className={`${classColor} 
                            ${shrink ? "text-sm sm:text-md sm:w-24" : "text-sm sm:text-xl sm:w-32"}
                            sm:font-bold  rounded-md shadow cursor-pointer text-center w-20 
                            px-2 py-1 sm:px-4 sm:py-2 
                             block 
                            
                            `}
                            onClick={onClick}
                          >
                            {message}
                          </div>
                        </div>
                      </div>
            </>)
}
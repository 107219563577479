import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import SvgJsx from "../templateux/svg/svg-jsx";

export default function ProfileFollowsYou ({
     classColor=`
                 bg-gray-700 text-gray-400 hover:text-white
                
                `
    ,message=`follows you`
    ,showOptions=false
    ,onAccept=()=>{}
    ,onReject=()=>{}
    ,follow_status="1"
}) {

    const [show_buttons,set_show_buttons] = useState(showOptions)
    const [isMounted,setIsMounted] = useState(false); // Need this for the react-tooltip

    useEffect(() => {
        setIsMounted(true);
    },[]);

    return (<>
            <div className="flex items-center content-center">
                {!show_buttons &&
                <div 
                    className={` ${classColor} px-1 sm:px-1.5 py-0 rounded-md text-2xs sm:text-xs whitespace-nowrap`}
                    data-tip={message}
                    data-for={`followoptions`}
                >
                    <SvgJsx 
                      type='fill'
                      icon="eye-sm"
                      className={`h-3 w-3 sm:h-4 sm:w-4 cursor-pointer`}
                      title={message}
                    
                    />
                </div>
                }
                {show_buttons &&
                <>
                <div 
                    className={` cursor-pointer mr-1  
                    bg-green-700 text-green-400 hover:text-white
                     px-1 sm:px-1.5 py-0 rounded-md text-2xs sm:text-xs whitespace-nowrap`}
                    onClick={()=> {
                        onAccept();
                        set_show_buttons(false);
                    }}
                    data-tip={'Approve follow request'}
                    data-for={`followoptions`}
                >
                    <SvgJsx 
                      type='fill'
                      icon="check-circle-sm"
                      className={`h-3 w-3 sm:h-4 sm:w-4`}
                      title={'Accept follow request'}
                    
                    />
                </div>
                {follow_status?.toString() !== "0" &&
                <div 
                    className={` cursor-pointer bg-red-700 text-red-400 hover:text-white
                     px-1 sm:px-1.5 py-0 rounded-md text-2xs sm:text-xs whitespace-nowrap`}
                    onClick={()=> {
                        onReject();
                        set_show_buttons(false);
                    }}
                    data-tip={'Ignore follow request'}
                    data-for={`followoptions`}
                >
                    <SvgJsx 
                      type='fill'
                      icon="x-circle-sm"
                      className={`h-3 w-3 sm:h-4 sm:w-4 `}
                      title={'Ignore follow request'}
                    
                    />
                </div>
                }
                </>}
            </div>
            {isMounted && <ReactTooltip
                id={`followoptions`}
                place={`bottom`} 
                effect='solid' 
                clickable={true}
                delayHide={200}
                delayUpdate={0}
                className="reacttooltip rounded-3xl shadow-lg"
            />
            }
    </>)
}
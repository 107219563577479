export function countUpDuration(date,timestamp=+new Date,seconds=0) {
    
    const thisdate = +new Date(date)

    // Make a fuzzy time
    var delta = ((((+new Date(timestamp) - thisdate) / 1000)+(Math.floor(seconds))));

    var breakdown = countUpBySeconds(delta)

    return breakdown
}

export function countUp(date,timestamp=+new Date) {
    
    const thisdate = +new Date(date)

    // Make a fuzzy time
    var delta = Math.round((+new Date(timestamp) - thisdate) / 1000);

    var breakdown = countUpBySeconds(delta)

    return breakdown
}

export const countUpBySeconds = (delta) => {

    var  minute = 60
        ,hour = minute * 60
        ,day = hour * 24
        ,week = day * 7
        ,month = week * 4
        ,year = month * 12

    var vardays = Math.floor(delta / day )
    var delta_day = delta - (day * vardays)
    var varhours = Math.floor(delta_day / hour )
    var delta_hour = delta - (hour * varhours)
    var varminutes = Math.floor(delta_hour / minute)
    var delta_minute = delta_hour - (minute * varminutes)
    var varseconds = delta_minute 

    //return `${preptimer(varhours)}:${preptimer(varminutes)}:${preptimer(varseconds)}:`;

    return { secondTenths: 0
            ,seconds: varseconds
            ,minutes: varminutes
            ,hours: varhours
            ,days: vardays}
}

// function preptimer(n) { 
//     return n < 10 ? `0${n}` : `${n}`
// }

export function countUpSecondsOnly(date,timestamp=+new Date) {
    
    const thisdate = +new Date(date)

    // Make a fuzzy time
    var delta = Math.round((+new Date(timestamp) - thisdate) / 1000);

    return delta
    
}




import ProfileAvatar from "@/components/profile/item/profile-avatar"
import ProfileFollowsYou from "@/components/profile/profile-follows-you"
import { followerFormat } from "@/lib/utils/followerFormat"
import { openWindow } from "@/lib/utils/openWindow"
import { hasData } from "@/lib/utils/hasData"
import {  signIn, useSession } from "next-auth/client"
import Link from "next/link"
import * as React from "react"
import { useEffect, useState } from "react"
import toast, { toastConfig } from "react-simple-toasts"
import ProfileMenuOptions from "./profile-menu-options"
import ProfileFollow from "./profile-follow"
import { blockactions, config } from "@/lib/config"
import AuthContext from '@/components/auth/context'
import { isBlocked } from "@/lib/utils/isBlocked"
import isAdminUser from "@/lib/utils/isAdminUser"
import { idInJson } from "@/lib/utils/idInJson"
import { useConfirm } from "@/lib/utils/hooks/useConfirm"


export default function ProfileSingle({ 
               itemdata
              ,userdata
              ,shrink=false
              ,owner_block_json
              ,owner_name
              ,owner_id                  //user_id
              ,owner_bol_staff_help    //bol_staff_help
              ,owner_staff_json        //staff_json
              ,owner_bol_admin_help    //bol_admin_help
              ,placeholder
              ,navtype
              ,helptype
              ,isProfileHeader
             }) {

              const { isConfirmed } = useConfirm();




              const authContext = React.useContext(AuthContext)

              const [user_name,set_user_name] = useState(hasData(itemdata?.user_name) ? itemdata?.user_name : owner_name)
              const [profile_link,set_profile_link] = useState(`/${itemdata?.user_name?.toLowerCase()}`)
              
               
              const [session,loading] = useSession()
              const [state_following_them,set_state_following_them] = useState(itemdata?.following_them > 0 ? true : false)
              const [state_following_them_status,set_state_following_them_status] = useState(hasData(itemdata?.following_them_status) ? itemdata?.following_them_status : false)
              const [followers_total,set_followers_total] = useState(hasData(itemdata?.followers) ? itemdata?.followers : 0)
              const [isUserBlocked,set_isUserBlocked] = useState(isBlocked( 
                                                          owner_block_json
                                                          , itemdata?.timestamp
                                                          , itemdata?.user_name))
              const [isIgnored,set_isIgnored] = useState(idInJson(userdata?.ignore_json,itemdata?.id))

              useEffect(()=> {

                if (itemdata) {
                  set_followers_total(hasData(itemdata?.followers) ? parseInt(itemdata?.followers) : 0);
                  set_user_name(hasData(itemdata?.user_name) ? itemdata?.user_name : owner_name);
                  set_profile_link(`/${itemdata?.user_name?.toLowerCase()}`)
                }
                
                if (itemdata && !loading && userdata) {
                  set_state_following_them(hasData(itemdata?.following_them) ? parseInt(itemdata?.following_them) > 0 : false);
                  set_state_following_them_status(hasData(itemdata?.following_them_status) ? parseInt(itemdata?.following_them_status) : 1);
                  set_isUserBlocked(isBlocked( 
                                            owner_block_json
                                            , itemdata?.timestamp
                                            , itemdata?.user_name))
                  set_isIgnored((hasData(itemdata?.id) && hasData(userdata?.ignore_json)) 
                                  ? idInJson(userdata?.ignore_json,itemdata?.id) 
                                  : false)
                }

              },[
                 itemdata
                ,loading
                ,userdata
                ,owner_name
                ,owner_block_json
              ])

            

              

              const followApprove = async (id,status) => {
                const api_str = `/api/private/user-follow/approve?id=${id}&status=${status}`
                //console.log("api_str",api_str)
                if (hasData(id)) {
                  const res = await fetch(api_str);
                  const json = await res.json()
                  if (json) {
                    //console.log("json",json)
                    const { action } = json
                    //console.log("approval status", status)
                    toastConfig({
                      time: 1000,
                      className: '',
                      position: 'right'
                     });
                    toast(status == "1" ? `Follow request approved` : `Follow removed`, { time: 3000, className: '', clickable: true, clickClosable: true })
                    
                  }
                }
              }
              
              const followHandler = async (id,bol_following,user_name) => {

                var confirmed = true;
                if (bol_following) {
                  //do confirmation check
                  confirmed = await isConfirmed(`Are you sure you want to unfollow ${user_name}?`);
                }
                
                if (confirmed) {
                  if (hasData(id)) {
                    const res = await fetch(`/api/private/user-follow/insert?id=${id}`);;
                    const json = await res.json()
                    if (json) {
                      // console.log("json",json)
                      const { action, status } = json
                      //console.log("follow status", status)
                      set_state_following_them_status(status);
                      if (status >= 1) set_followers_total(followers_total+(action >= 1 ? 1 : -1))
                      if (action>=1) { 
                        await set_state_following_them(true)
                      } else { 
                        await set_state_following_them(false)
                      } 
                    }
                  }
                }
                
              }
            
              //BLOCKS
              const blockHandler = async (owner_id,target_id,username,action) => {
                const confirmed = await isConfirmed(`Are you sure you want to ${blockactions.filter((e)=> e?.action == action)[0]?.action_short} ${username} ${blockactions.filter((e)=> e?.action == action)[0]?.js_amount}?`);
                // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
               if (confirmed) {
                  if (hasData(target_id)) {
                    const res = await fetch(`/api/private/user-block/insert?owner_id=${owner_id}&target_id=${target_id}&action=${action}`);;
                    const json = await res.json()
            
                    if (json) {
                      const { outcome } = json[0][0]
                      //console.log(outcome)
                      if (outcome>=1) { 
                        toast(`${username} has been ${blockactions.filter((e)=> e?.action == action)[0]?.action_past} ${blockactions.filter((e)=> e?.action == action)[0]?.js_amount}.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                      } else {
                        toast(`You dont have permission to do that.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                      }
                    }
                  }
                }
                
              }

              //IGNORE
              const ignoreHandler = async (target_id,action,target_name) => {
                const confirmed = await isConfirmed(`Are you sure you want to ${action=="1" ? "ignore" : "un-ignore"} ${target_name}?`);
                // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
               if (confirmed) {
                  if (hasData(target_id)) {
                    const res = await fetch(`/api/private/user-ignore/insert?target_id=${target_id}&action=${action}`);
                    const json = await res.json()
                    if (json) {
                      
                      const { outcome, ignore_json } = json[0][0]

                      await authContext.set_contextValue(({ 
                        ...authContext
                        , ignore_json: ignore_json
                      }))

                      if (action=="1") { 
                        await set_isIgnored(true)
                      } else { 
                        await set_isIgnored(false)
                      } 

                      if (outcome?.toString() == "1") { 
                        toast(`${target_name} has been ${action=="1" ? "ignored" : "un-ignored"}. You can manage ignored users in your personal settings.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                      } else {
                        toast(`Something went wrong. Please try again.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                      }
                    }
                  }
                }
                
              }

              //ADMIN
              const adminHandler = async (target_id,setting,type,target_name) => {
                const confirmed = await isConfirmed(`Are you sure you want to ${type} for ${target_name}?`);
                // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
               if (confirmed) {
                  if (hasData(target_id)) {
                    //alert(`/api/private/admin/actions?target_id=${target_id}&setting=${setting}&name=${target_name}`)
                    const res = await fetch(`/api/private/admin/actions?target_id=${target_id}&setting=${setting}&name=${target_name}`);
                    const json = await res.json()
                    if (json) {

                      console.log("json",json)
                      
                      const { outcome } = json[0][0]

                      if (["1","0"].indexOf(outcome?.toString()) > -1) { 
                        toast(`"${type}" completed for ${target_name}.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                      } else {
                        toast(`Something went wrong. Please try again.`, { time: 3000, className: '', clickable: true, clickClosable: false });
                      }
                    }
                  }
                }
                
              }


    const avatar=<>
                <ProfileAvatar 
                  // we disable the visibility of a broadcaster on public pages if they have notifications turned off... 
                  // the second part of this statement allows it still to be seen on their profile since we aren't 
                  // passing bol_send_notifications through all db calls.
                  isPlaying={false}
                  isContributor={itemdata?.contributor_level > 0}
                  shrink={itemdata?.bol_credentials?.toString() == "1" ? true : shrink}
                  start_date={itemdata?.stream_start_date}
                  timestamp={itemdata?.timestamp}
                  placeholder={placeholder}
                  bol_caster={itemdata?.bol_credentials?.toString() == "1"}
                  avatar_url={itemdata?.avatar_url}
                  avatar_url_sfw={itemdata?.avatar_url_sfw}
                  bol_nsfw={itemdata?.bol_nsfw}
                  avatar_crdate={itemdata?.avatar_crdate}
                  id={itemdata?.id}
                  user_name={itemdata?.user_name}
                  isOwner={owner_id?.toString()==itemdata?.id?.toString()}
                  hasData={hasData(itemdata?.id)}
                />
    </>

    return (  



      <li className={`list-none ${shrink ? "px-4" : ""}
      `}>
      {/* OVERALL CONTAINER */}
      <div className="flex items-center content-center h-24 ml-4">

        {/* AVATAR */}
        {/* OFFLINE OR GLOBAL */}
        {(!isProfileHeader) &&
        <div className={`flex-0 ${shrink ? "mr-4 " : "mr-4 mt-4"} `}
        >
          <Link href={profile_link}>
              <a className="cursor-pointer flex items-center content-center">
                {avatar}
              </a>
          </Link>
        </div>
        }

        {/* ONLINE AND PROFILE HEADER */}
        {(isProfileHeader) &&
        <div 
          className={`flex-0 ${shrink ? "mr-4 " : "mr-4 -mt-1"}  `}
        >
              <div className=" flex items-center content-center">
                {avatar}
              </div>
        </div>
        }


        {/* EVERYTHING ELSE */}
        <div className="flex-1 flex items-center content-center">
          


            {/* ALL CONTAINER */}
            <div className="flex-1 sm:flex items-center content-center w-full">
              
              {/* name */}
              <div className="flex-1">

              
                      <div className="flex items-center content-center">

                        {/* NAME AND CONTRIBUTOR_LEVEL  and FOLLOWER COUNT TAGS*/}
                        <div className="flex-0 ml-2 ">

                              <Link href={profile_link}>
                                <a className="cursor-pointer flex items-center content-center flex-0 
                                 text-gray-100  hover:text-gray-300
                                ">

                                  {/* NAME AND CONTRIBUTOR_LEVEL */}
                                  <div className="flex items-center content-center">
                                  
                                    {/* NAME */}
                                    {!shrink &&
                                    <h1 className={`font-bold  flex-0 text-lg sm:text-3xl`}>
                                      {user_name}
                                    </h1>
                                    }
                                    {shrink &&
                                    <h3 className={`font-bold flex-0 text-md sm:text-xl`}>
                                      {user_name} 
                                    </h3>
                                    }

                                   
                                  </div>
                              
                                </a>
                              </Link>

                              {/* FOLLOWER COUNT & TAGS */}
                              <div className={` flex items-center content-center`}>
                                <Link href={`/${user_name?.toLowerCase()}/followers`}>
                                
                                  <a className={`text-xs sm:text-sm md:text-md mr-1 sm:mr-3 whitespace-nowrap
                                                  no-underline hover:underline
                                                   text-gray-100
                                  `}>
                                    {followerFormat(followers_total,"follower","followers")}
                                  </a>
                                </Link>
                                {itemdata?.following_me > 0 && itemdata?.following_me_status >= 1 &&
                                <div className="flex flex-0 mt-1 mr-1 sm:mr-3 ">
                                  <Link href={`/${user_name?.toLowerCase()}/following`}>
                                  <a title={`follows you`}>
                                    <ProfileFollowsYou />
                                  </a>
                                  </Link>
                                 
                                </div>
                                }
                                {itemdata?.following_me > 0 && itemdata?.following_me_status < 1 &&
                                <div className="flex items-center content-center flex-0 mt-1 mr-1 sm:mr-3 ">
                                  
                                  <div 
                                  >
                                    <ProfileFollowsYou 
                                      showOptions={true}
                                      onAccept={()=>followApprove(itemdata?.following_me,1)}
                                      onReject={()=>followApprove(itemdata?.following_me,0)}
                                      follow_status={itemdata?.following_me_status}
                                    />
                                  </div>
                                  
                                </div>
                                }
                                
                              </div>
                        </div>
                      
                      </div>
                
              </div>

              {/* FOLLOW BUTTON & MORE */}
              <div className="flex-0 flex items-center content-center mt-2 sm:mt-0">

                      {/* Loading */}
                      {(loading || placeholder) &&
                      <ProfileFollow
                        classColor='bg-gray-500 text-white cursor-progress animate-pulse'
                        onClick={()=> {
                          toastConfig({
                            time: 1000,
                            className: '',
                            position: 'right'
                           });
                          toast(`Still loading. Hold yer horses there bucko...`, { time: 3000, className: '', clickable: true, clickClosable: true })
                          
                          
                        }}
                        message="Follow"
                        shrink={shrink}
                      />
                      }

                      {/* FOLLOW - NOT LOGGED IN*/}
                      {!loading && !session && !placeholder &&
                      <ProfileFollow
                        classColor='bg-brand hover:bg-blue-600 text-white'
                        onClick={signIn}
                        message="Follow"
                        shrink={shrink}
                      />
                      }

                      {/* FOLLOW - LOGGED IN*/}
                      {!loading && session && !placeholder && itemdata?.id != userdata?.id && 
                      <ProfileFollow
                        classColor={`${state_following_them ? 'bg-gray-500' : 'bg-brand hover:bg-blue-600'}  text-white`}
                        onClick={()=>followHandler(itemdata?.id,(state_following_them && state_following_them_status >= 1),itemdata?.user_name)}
                        message={(state_following_them && state_following_them_status >= 1) 
                                ? `Following` 
                                : state_following_them && state_following_them_status < 1
                                  ? 'Requested'
                                  : 'Follow'
                                } 
                        shrink={shrink}
                      />
                      }

                     

                       {/* GO LIVE - OWNER ONLY - NOT LIVE */}
                       {!loading && session && !placeholder && itemdata?.id == userdata?.id && !status && (!hasData(userdata?.contributor_level) || userdata?.contributor_level?.toString() == "0") &&

                        <ProfileFollow
                          classColor={`bg-red-600 hover:bg-red-500 text-white`}
                          onClick={()=> {
                            if (typeof window !== undefined) {
                              openWindow(config.broadcast.app_base_url, config.broadcast.title, window, config.broadcast.width, config.broadcast.height);
                            }
                          }}
                          message='Broadcast'
                          shrink={shrink}
                        />
                      }
                      
                      
                  

              </div>

            </div>   

            

        </div>

        {/* MORE BUTTON ALWAYS ON RIGHT SIDE */}
        <div className="flex-0">
            {/* MORE */}
            {session &&
            <div className="flex-0 ml-2 flex items-center content-center">
              
              <ProfileMenuOptions 
                itemdata={itemdata}
                followApprove={followApprove}
                blockHandler={blockHandler}
                isBlocked={isUserBlocked}
                ignoreHandler={ignoreHandler}
                adminHandler={adminHandler}
                isIgnored={isIgnored}
                isSiteAdmin={userdata?.bol_admin?.toString()=="1"}
                isAdmin={isAdminUser(  owner_id                  //user_id
                                      ,owner_bol_staff_help    //bol_staff_help
                                      ,owner_staff_json        //staff_json
                                      ,owner_bol_admin_help    //bol_admin_help
                                      ,userdata?.id?.toString()                            //my_id
                                      ,userdata?.bol_admin?.toString()               //global_admin
                                      ,'profile'
                                      )}
                userdata={userdata}
                session={session}
                owner_id={owner_id}
              />
              
            </div>
            }
        </div> 
        
      </div>
          
      </li>
  )
}

  

import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import { blockactions } from "@/lib/config";
import { Fragment, useRef } from "react";
import Popup from "reactjs-popup";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { hasData } from "@/lib/utils/hasData";
import { useRouter } from "next/router";

export default function ProfileMenuOptions({
   itemdata
  ,followApprove
  ,blockHandler
  ,isBlocked
  ,ignoreHandler
  ,adminHandler
  ,isIgnored
  ,isSiteAdmin
  ,isAdmin
  ,userdata
  ,session
  ,owner_id
}) {



  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {if (ref.current) ref.current.close()};
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};
  const router = useRouter()


  return (
    <div className="menu flex items-center content-center  flex-0  bg-gray-900 ">
    <Popup
      trigger={<div 
                aria-haspopup="true"
                className="menu-item w-5 h-5 flex-0 "
                > 
                  <SvgJsx 
                    type='outline' 
                    icon='dots-v' 
                    className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                                   text-gray-400  hover:text-gray-200`} 
                    title='More'
                  />  
                </div>}
      position="left top"
      on="click"
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex: 999  }}
      arrow={false}
      nested
      ref={ref}
    >
      <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2 z-[1000]
                     bg-gray-800 "
      >

        {/* GOTO PROFILE */}
        <PopupMenuOption
          isDisplay={true}
          onClick={()=>{
            closeMenu();
            router.push(`/${itemdata?.user_name}`)
          }}
          icon="profile-sm"
          title="Profile"
        />

        
        {/* SETTINGS */}
       
        <PopupMenuOption
          isDisplay={isAdmin}
          onClick={()=>{
            closeMenu();
            router.push(`/${itemdata?.user_name}/settings`)
          }}
          icon="settings-sm"
          title={'Settings'} 
        />

        {/* APPROVE FOLLOW REQUEST */}
        <PopupMenuOption
          isDisplay={session && itemdata?.following_me > 0 && itemdata?.following_me_status < 1}
          onClick={()=>{
            closeMenu();
            followApprove(itemdata?.following_me,1);
          }}
          icon="eye-sm"
          title={`Approve Follower`}
        />

        <PopupMenuOption
          isDisplay={session && itemdata?.following_me > 0 && itemdata?.following_me_status < 1}
          onClick={()=>{
            closeMenu();
            followApprove(itemdata?.following_me,0);
          }}
          icon="eye-sm"
          title={`Reject Follower`}
        />

        <PopupMenuOption
          isDisplay={session && itemdata?.following_me > 0 && itemdata?.following_me_status >= 1}
          onClick={()=>{
            closeMenu();
            followApprove(itemdata?.following_me,0);
          }}
          icon="eye-sm"
          title={`Remove Follower`}
        />


        {/* IGNORE */}
        <PopupMenuOption
          isDisplay={(userdata?.id !== itemdata?.id)} //&& (userdata?.id == owner_id)) }
          onClick={()=>{
            closeMenu();
            ignoreHandler(itemdata?.id,isIgnored ? "0" : "1",itemdata?.user_name)}}
          icon="x-circle-sm"
          title={isIgnored ? 'Un-Ignore' : 'Ignore'} 
        />

        

        {/* UNBLOCK */}
       
        <PopupMenuOption
          isDisplay={((isAdmin && userdata?.id !== itemdata?.id) && isBlocked)}
          onClick={()=>{
            closeMenu();
            blockHandler(owner_id,itemdata?.id,itemdata?.user_name,-1)}}
          icon="x-circle-sm"
          title={'Unblock'} 
        />

        
         {/* BLOCK */}
         {blockactions.filter((e)=>['0','1','2','3','4','5','6'].indexOf(e.action.toString()) >= 0).map((e,index) => 
          <Fragment key={index}>
            <PopupMenuOption
              isDisplay={((isAdmin && userdata?.id?.toString() !== itemdata?.id?.toString() && itemdata?.id?.toString() !== owner_id?.toString()) && !isBlocked)}
              onClick={()=>{
                closeMenu();
                blockHandler(owner_id,itemdata?.id,itemdata?.user_name,e.action)}}
              icon="x-circle-sm"
              title={e.button_msg} 
            />
          </Fragment>
          )}
        
      {/* {isAdmin && 
      <>
        <Popup
          trigger={
            
                  <div 
                    aria-haspopup="true"
                    className="menu-item "
                    > 
                     <PopupMenuOption
                        isDisplay={((isAdmin && userdata?.id?.toString() !== itemdata?.id?.toString() && itemdata?.id?.toString() !== owner_id?.toString()) && !isBlocked)} //&& (userdata?.id == owner_id)) }
                        icon="scale-sm"
                        title={'User Moderation'} 
                      />
                    </div>}
          position="left center"
          on="click"
          closeOnDocumentClick
          closeOnEscape
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent' }}
          arrow={false}
          nested
          ref={ref}
        >
          <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                       bg-gray-800 "
          >

           
                        {/* BLOCK */}
                          {/* {blockactions.filter((e)=>['0','1','2','3','4','5','6'].indexOf(e.action.toString()) >= 0).map((e,index) => 
                          <Fragment key={index}>
                            <PopupMenuOption
                              isDisplay={((isAdmin && userdata?.id?.toString() !== itemdata?.id?.toString() && itemdata?.id?.toString() !== owner_id?.toString()) && !isBlocked)}
                              onClick={()=>{
                                closeMenu();
                                blockHandler(owner_id,itemdata?.id,itemdata?.user_name,e.action)}}
                              icon="x-circle-sm"
                              title={e.button_msg} 
                            />
                          </Fragment>
                          )}

          </div>
        </Popup>
      </> 
      } */}

      {/* ADMIN STUFF */}
      {(session && isSiteAdmin) &&
      <>
        <div className="w-full pt-2 block text-sm font-bold text-red-500">
          Admin Only
        </div>

       {/* CONTRIBUTION */}
        <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'contribution',!hasData(itemdata?.contribution_date) ? 'Add Contribution' : 'Remove contribution',itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={!hasData(itemdata?.contribution_date) ? 'Add Contribution' : 'Remove contribution'} 
          />

      {/* CONTRIBUTOR LEVEL */}
      <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'contributor',itemdata?.contributor_level?.toString() == "0" ? 'Add Contributor' : 'Remove contributor',itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={itemdata?.contributor_level?.toString() == "0" ? '+ContributorLevel' : '-ContributorLevel'} 
          />

        {/* BROADCAST */}
        <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'broadcast',`${itemdata?.bol_credentials?.toString() == "0" ? 'Grant' : 'Remove'} broadcast credentials`,itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={`${itemdata?.bol_credentials?.toString() == "0" ? 'Grant' : 'Remove'} Broadcast`} 
          />

          {/* RECOMMEND */}
          <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'recommend',`${itemdata?.bol_recommended?.toString() == "0" ? 'Add to' : 'Remove from'} Recommended`,itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={`${itemdata?.bol_recommended?.toString() == "0" ? 'Add to' : 'Remove from'} Recommended`} 
          />

          {/* CREATOR */}
          <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'creator',`${itemdata?.bol_featured?.toString() == "0" ? 'Add' : 'Remove'} Creator`,itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={`${itemdata?.bol_featured?.toString() == "0" ? 'Add' : 'Remove'} Creator`} 
          />


          {/* FEATURED */}
          <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'feature',`${itemdata?.bol_featured?.toString() == "0" ? 'Add' : 'Remove'} Featured`,itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={`${itemdata?.bol_featured?.toString() == "0" ? 'Add' : 'Remove'} Featured`} 
          />

          {/* FEATURED */}
          <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'discord',`${itemdata?.bol_discord_announce?.toString() == "0" ? 'Add' : 'Remove'} Featured`,itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={`${itemdata?.bol_discord_announce?.toString() == "0" ? 'Add' : 'Remove'} Discord`} 
          />

          {/* SEO */}
          <PopupMenuOption
            isDisplay={((session && isSiteAdmin))}
            onClick={()=>{
              closeMenu();
              adminHandler(itemdata?.id,'seo',`${itemdata?.bol_seo?.toString() == "0" ? 'Add' : 'Remove'} SEO`,itemdata?.user_name)}}
            icon="lock-closed-sm"
            title={`${itemdata?.bol_seo?.toString() == "0" ? 'Add' : 'Remove'} SEO`} 
          />


          {/* BAN */}
          <PopupMenuOption
              isDisplay={((session && isSiteAdmin))}
              onClick={()=>{
                closeMenu();
                adminHandler(itemdata?.id,'ban','ban the account',itemdata?.user_name)}}
              icon="lock-closed-sm"
              title={'Ban Account'} 
          />

          {/* DELETE */}
          <PopupMenuOption
              isDisplay={((session && isSiteAdmin))}
              onClick={()=>{
                closeMenu();
                adminHandler(itemdata?.id,'delete','delete the account',itemdata?.user_name)}}
              icon="lock-closed-sm"
              title={'Delete Account'} 
          />

      </>
      }

      


      </div>
    </Popup>
  </div>
  );


  
}
